@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');


body{
    background: #121212;
    color: white;
    
}
h1{
    font-family: 'Poppins';
    font-weight: bold;
    display: block;
    text-align: center;
    color: #bb86FC;
}

.inputs{
    background: #212529;
    width: 70%;
    margin: auto;
    border-radius: 1em;
    padding: .5em;
    margin-bottom: 20px;
}

label{
    padding: .3em 1em .3em 1em;
    text-decoration: underline;
}
.input{
    text-align: center;
}
button{
    margin:auto;
    margin-top: .2em;
    display: block;
}

.errorContainer {
    text-align: center;
}

h2{
    background: rgb(221, 117, 117);
    display: inline-block;
    margin: auto;
    color: black;
    padding: .5em;
    border: 2px solid red;
    border-radius: .5em;
    
}

